<template>
  <div>
    <div :class="'mobileNavBar ' + deviceType">
      <div class="appBar-header">
        <div @click="routeBack('/'+seller.username,false)" class="header-route-back">
        <span v-if="deviceType === 'ios'">
          <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
          <span v-else>
          <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
        </span>
        </div>
        <div class="header-title-container">
          <p class="header-title">
            İş İlanı Detayı
          </p>
        </div>
      </div>
    </div>
    <div v-if="pageLoaded" style="margin-top: 56px">
      <loader></loader>
    </div>
    <div v-else>
      <div style="padding-top: 56px"></div>
      <div class="deed-detail">
        <div class="deed-detail-swiper">
          <div v-if="gigPortfolios.length === 1">
            <div @click="playMedia(gigPortfolios[0])" style="position: relative">
              <!-- play button icon -->
              <div
                v-if="!gigPortfolios[0].showIframe && gigPortfolios[0].videoURL && (gigPortfolios[0].fileType === 'audio' || gigPortfolios[0].fileType === 'video')"
                class="playIcon">
                <img @click="gigPortfolios[0].showIframe = true;" class="deed-detail-playMediaIcon" :src="$root.CDN_SITE + 'icon/ic_play_circle_fill.svg'">
              </div>
              <div v-if="videoLoader && gigPortfolios[0].videoURL && (gigPortfolios[0].fileType === 'audio' || gigPortfolios[0].fileType === 'video')"
                   style="position: relative;width: 100%;height: 100%;">
                <img class="deed-detail-image" :style="'width:100vw;height:'+ screenWidth/1.77 +'object-fit:cover;position:relative'"
                     :src="gigPortfolios[0].imageURL">
                <div style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                  <loader style="height: 30px;width: 30px;"></loader>
                </div>
              </div>
              <!-- padding value is 15 therefore width = (screenWidth- 2*paddingValue) , 2 = padding left + padding-right-->
              <img class="deed-detail-image" v-if="!gigPortfolios[0].showIframe" :style="'width:100%;height:'+ screenWidth/1.77 +'position:relative'"
                   :alt="title"
                   :src="gigPortfolios[0].imageURL"/>
              <div v-if="gigPortfolios[0].showIframe">
                <iframe @load="deactivateIframeLoader()" v-show="!videoLoader" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"
                        :width="screenWidth" :height="screenWidth/1.77" frameborder="0" scrolling="no"
                        :src="'https://api.bionluk.com/general/video/' + gigPortfolios[0].uuid+'/mobilex'+(screenWidth)+'/'"></iframe>
              </div>
            </div>
          </div>
          <swiper :options="swiperOption" ref="mySwiper" v-else-if="gigPortfolios.length > 1">
            <swiper-slide v-for="(portfolio, index) in gigPortfolios" :key="index">
              <div>
                <div class="playIcon"
                     v-if="!portfolio.activatedIframe && portfolio.videoURL && (portfolio.fileType === 'audio' || portfolio.fileType === 'video')">
                  <img class="deed-detail-playMediaIcon" @click="activateIframe(portfolio)"
                       :src="$root.CDN_SITE + 'icon/ic_play_circle_fill.svg'">
                </div>

                <div v-if="videoLoader && portfolio.videoURL && (portfolio.fileType === 'audio' || portfolio.fileType === 'video')" style="position:relative">
                  <img :style="'width:100%;object-fit:cover;height:'+ screenWidth/1.77 +''" :src="portfolio.imageURL">
                  <div style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                    <loader style="height: 30px;width: 30px;"></loader>
                  </div>
                </div>

                <div v-if="portfolio.activatedIframe">
                  <iframe
                    @load="deactivateIframeLoader()"
                    v-show="!videoLoader"
                    allowfullscreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    :width="screenWidth"
                    :height="screenWidth/1.77"
                    frameborder="0"
                    scrolling="no"
                    :src="'https://api.bionluk.com/general/video/' + portfolio.uuid+'/mobilex'+(screenWidth)+'/'">
                  </iframe>
                </div>


                <div v-if="gigPortfolios.length > 1">
                  <div v-if="!videoLoader && !portfolio.activatedIframe">
                    <img class="image-div" :width="screenWidth" :height="screenWidth/1.77" :src="portfolio.imageURL"/>
                  </div>
                </div>
                <div v-else>
                  <div v-if="!portfolio.activatedIframe">
                    <img :width="screenWidth" :height="screenWidth/1.77" :src="portfolio.imageURL"/>
                  </div>
                </div>

              </div>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
          </swiper>
        </div>
        <div class="deed-detail-user-container">
          <div class="deed-detail-user-info-container">
            <div class="deed-detail-user-info">
              <div @click="openUserProfilePage(seller.username)" class="deed-detail-user-image-section">
                <img class="deed-detail-user-image" :src="seller.avatar_url" :alt="seller.username">
                <span class="deed-detail-user-online" :style="{'display': seller.onlineStatus ? 'inline-block' : 'none'}"></span>
              </div>
              <div class="deed-detail-user-name-online-container">
                <div @click="openUserProfilePage(seller.username)" class="deed-detail-user-name-section">
                  <span class="deed-detail-user-name">{{ seller.username }}</span>
                </div>
                <div class="deed-detail-show-more-less">
                  <span class="deed-detail-show-more-less-text">{{ seller.title }}</span>

                </div>
              </div>
            </div>
            <button @click="sendMessage(seller.username)" class="deed-detail-user-send-message-button">
              Mesaj Gönder
            </button>
          </div>

        </div>
        <div class="deed-detail-short-description-container">
          <div class="deed-detail-short-description-section">
            <h1 class="deed-detail-short-description-text">Ben {{ title }}</h1>
          </div>
        </div>
        <div style="padding: 0 15px">
          <div v-if="gigDescription && gigDescription.length" class="deed-detail-long-description-container">
            <h4 class="deed-detail-long-description-header">Özet</h4>
            <ul class="deed-detail-long-description-list">
              <li class="deed-detail-long-description-list-item">
                <div class="deed-detail-long-description-list-item-detail">
                  <p v-html="gigDescription" class="deed-detail-long-description-list-item-detail-text"
                     :style="{'height': seemoreDescription ? '30vw' : 'auto'}"></p>
                </div>
              </li>
              <div class="deed-detail-long-description-list-see-more-background" :style="{'height': seemoreDescription ? '50%' : '0'}"></div>
            </ul>
            <a @click="seemoreDescription = !seemoreDescription" class="deed-detail-long-description-list-see-more-link">
              {{ seemoreDescription ? 'Daha fazla göster' : 'Daha az göster' }}
            </a>
          </div>

          <div class="packages-pricing-container">

            <div class="packages-container" v-if="packageCount === 3">
              <div class="package-segment-container">
                <p :class="{active: packageSegment === 'premium', segment: true}" @click="packageSegment = 'premium'">Pro</p>
                <p :class="{active: packageSegment === 'standard', segment: true}" @click="packageSegment = 'standard'">Standart</p>
                <p :class="{active: packageSegment === 'basic', segment: true}" @click="packageSegment = 'basic'">Temel</p>
              </div>
              <p class="package-content-title">{{packages[packageSegment].title}}</p>
              <div class="package-description">{{packages[packageSegment].description}}</div>
            </div>
            <div class="package-include" v-if="packages && !isEmpty(packages[packageSegment])">
              <p class="package-content-title" style="margin-top: 0; margin-bottom: 10px;">Ne İçeriyor?</p>
              <div class="content-scope" v-if="packages[packageSegment].duration > 0">
                <img class="content-img" src=" https://gcdn.bionluk.com/site/icon/green_tick.svg"/>
                <p class="content-title">{{packages[packageSegment].duration}} günde teslim</p>
              </div>
              <div class="content-scope" v-if="packages[packageSegment].revisions > 0">
                <img class="content-img" src=" https://gcdn.bionluk.com/site/icon/green_tick.svg"/>
                <p class="content-title">{{packages[packageSegment].revisions}} revizyon</p>
              </div>
              <div class="content-scope" v-for="option in packages[packageSegment].options">
                <img class="content-img" src=" https://gcdn.bionluk.com/site/icon/green_tick.svg" v-if="option.value"/>
                <img class="content-img" style="opacity: 0.7" src="https://gcdn.bionluk.com/site/icon/gray_tick.svg" v-if="!option.value"/>
                <p :class="{'content-title':true, passive: !option.value}"><span v-if="typeof option.value !== 'boolean'">{{option.value}}</span><span v-if="option.selectboxHelpText"> {{option.selectboxHelpText}}</span> {{option.title}}</p>
              </div>
            </div>



            <template v-if="packageCount === 1">
              <div class="gig-quantity" v-if="!packages && !packages.basic || isEmpty(packages.basic.extra_words)">
                <p>İş Adedi</p>
                <select class="deed-detail-order-quantity-select" v-model="quantity">
                  <option v-for="x in 20">
                    <span>{{ x }}</span>
                  </option>
                </select>
              </div>

              <div v-else class="gig-quantity">
                <p>Kelime Sayısı: </p>
                <input v-model="words" type="number" style="width: 70px">
              </div>
            </template>




            <div v-if="packages.extras && packages.extras.length" class="deed-detail-extras">
              <h4 class="deed-detail-extras-header">Ekstralar</h4>
              <ul class="deed-detail-extras-list">
                <li v-for="extra in packages.extras" class="deed-detail-extras-list-item">
                  <div class="deed-detail-extras-list-item-detail">
                    <div style="display: flex">
                      <div class="deed-detail-extras-list-item-detail-control">
                        <input @change="selectExtra(extra, $event)" class="deed-detail-extras-list-item-detail-control-input" type="checkbox"
                               :value="`extra${extra.id}`" :id="`extra${extra.id}`">
                        <label :value="`extra${extra.id}`" :for="`extra${extra.id}`"></label>
                      </div>
                      <div class="deed-detail-extras-list-item-detail-description">
                        <p class="deed-detail-extras-list-item-detail-description-text"><span v-if="extra.value" style="padding-right: 5px" >{{ extra.value }}</span>{{ extra.name }}</p>
                        <p style="color: #4b4f52; font-size: 12px;">{{extra.description}}</p>
                        <span v-if="extra.duration" class="deed-detail-extras-list-item-detail-description-day">{{ extra.duration | specifyPeriod(units.period, 'day')  }}</span>
                      </div>
                    </div>
                    <div class="deed-detail-extras-list-item-detail-count">
                      <select  v-if="extra.id !== 'superfast'" class="deed-detail-extras-list-item-detail-count-selectbox" @change="selectPieceOfSelectedExtra(extra, $event)">
                        <option v-for="x in 20" :value="x" class="deed-detail-extras-list-item-detail-count-selectbox-option">
                          <span class="deed-detail-extras-list-item-detail-number">{{ x }}</span>
                          <span class="deed-detail-extras-list-item-detail-price">({{  x * extra.price | specifyCurrency(units.currency) }})</span>
                        </option>
                      </select>
                      <span v-else style="font-size: 16px; color: #4b4f52 ">
                        +{{extra.price | specifyCurrency(units.currency)}}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <button @click="giveOrderMobile(packages[packageSegment])" style="border: none;" class="buy-now-button" v-if="priceInfo.buttonAction === 'buy' || packageCount === 3">
              Devam Et
              ({{totalPayment(packageSegment)}} <span>{{ priceInfo.priceIcon }}</span>)
            </button>
            <button v-else @click="sendMessage(seller.username)" class="buy-now-button">
              {{ priceInfo.buttonText }}
              <span v-if="priceInfo.priceHelpText !== '1 İşin Tamamı'" style="margin: 0px 5px;">
                {{ priceInfo.priceHelpText }}: ({{priceInfo.price }} - {{priceInfo.maxprice}} {{ priceInfo.priceIcon}})
              </span>
              <span v-if="priceInfo.buttonAction !== 'message'">{{ totalPayment() }}  {{ priceInfo.priceIcon }}</span>
            </button>
          </div>

          <!--review-->
          <div v-if="gigCommentInfo.commentCount" class="deed-detail-reviews">
            <h5 class="deed-detail-reviews-header">Değerlendirmeler</h5>
            <div class="deed-detail-reviews-info">
              <div style="display: flex;align-items: center">
                <span class="deed-detail-reviews-info-rate">{{ gigCommentInfo.commentRating }}</span>
                <p class="deed-detail-reviews-info-text">puan <span class="deed-detail-reviews-info-review-count">{{ gigCommentInfo.commentCount }} <span>değerlendirme</span></span>
                </p>
              </div>
            </div>
            <div class="deed-detail-reviews-description">
              <ul class="deed-detail-reviews-description-list">
                <li v-for="grade in gigCommentInfo.commentGrades" class="deed-detail-reviews-description-list-item">
                  <div class="deed-detail-reviews-description-list-item-detail">
                    <div style="display: flex">
                      <div class="deed-detail-reviews-description-list-item-detail-image-section">
                        <img class="deed-detail-reviews-description-list-item-detail-image" :src="grade.icon" alt="image">
                      </div>
                      <div class="deed-detail-reviews-description-list-item-detail-text-section">
                    <span class="deed-detail-reviews-description-list-item-detail-text">
                      {{ grade.name }}
                    </span>
                      </div>
                    </div>
                    <div class="deed-detail-reviews-description-list-item-detail-rate">
                      <star-rating
                        v-model="grade.grade"
                        :max-rating="5"
                        :star-size="18"
                        :padding="2.3"
                        :show-rating="false"
                        inactive-color="#bfc8d2"
                        active-color="#ffb700"
                        :read-only="true"></star-rating>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="gigCommentInfo.commentCount" class="deed-detail-comments">
              <a @click="getGigComments()" class="deed-detail-comments-link"> {{ gigCommentInfo.commentCount }} yorum</a>
            </div>
            <div v-if="gigComments.comments" class="deed-detail-comments-container">
              <ul class="deed-detail-comments-list">
                <li v-for="comment in gigComments.comments" class="deed-detail-comments-list-item">
                  <div class="deed-detail-comments-list-item-detail">
                    <div class="deed-detail-comments-list-item-user-container">
                      <div class="deed-detail-comments-list-item-user">
                        <img @click="openUserProfilePage(comment.username)" class="deed-detail-comments-list-item-user-image" :src="comment.avatar_url"
                             alt="user-image">
                        <div style="display: flex;flex-direction: column;padding-left: 11px">
                          <span @click="openUserProfilePage(comment.username)" class="deed-detail-comments-list-item-user-name">{{ comment.username }}</span>
                          <div class="deed-detail-comments-list-item-user-rate">
                            <star-rating
                              v-model="comment.grade"
                              :max-rating="5"
                              :star-size="11"
                              :show-rating="false"
                              inactive-color="#bfc8d2"
                              active-color="#ffb700"
                              :read-only="true"></star-rating>
                          </div>
                        </div>
                      </div>
                      <div class="deed-detail-comments-list-item-user-comment-date">
                        <span class="deed-detail-comments-list-item-user-comment-date">{{ calculateDate(comment.created_at) }}</span>
                      </div>
                    </div>
                    <div class="deed-detail-comments-list-item-detail-comment-container">
                      <p v-html="comment.comment" class="deed-detail-comments-list-item-detail-comment-text" />
                    </div>
                    <div class="comment-reply-button"
                         @click="openReplyTextArea(comment.comment_id)"
                         v-if="seller.uuid === user.uuid && !comment.comment_reply && !answering[comment.comment_id] || (seller.uuid === user.uuid && !comment.comment_reply && answering[comment.comment_id] && !answering[comment.comment_id].answering) ">
                      <p>Yanıtla</p>
                    </div>
                  </div>
                  <div v-if="comment.comment_reply" class="deed-detail-comments-list-item-reply">
                    <div class="deed-detail-comments-list-item-reply-user-image-container">
                      <img :src="seller.avatar_url" alt="user-image" class="deed-detail-comments-list-item-reply-user-image">
                    </div>
                    <div style="padding-left: 11px;width: 100%">
                      <div class="deed-detail-comments-list-item-reply-user-info">
                          <span class="deed-detail-comments-list-item-reply-user-name">
                            {{ seller.username }}
                          </span>
                      </div>
                      <div class="deed-detail-comments-list-item-reply-date">
                        <span v-html="comment.comment_reply" class="deed-detail-comments-list-item-reply-comment">

                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="separator-review" v-if="!comment.comment_reply && answering[comment.comment_id] && answering[comment.comment_id].answering"></div>
                  <div class="comment-reply-text-area"
                       v-if="!comment.comment_reply  && answering[comment.comment_id] && answering[comment.comment_id].answering">
                    <img :src="seller.avatar_url"/>
                    <textarea v-model="answering[comment.comment_id].commentReply" maxlength="512" minlength="1"
                              placeholder="Yorum yaz..."></textarea>
                  </div>
                  <div style="display: flex; width: 200px; margin-left: auto">
                    <div class="comment-reply-save-button"
                         @click="cancelCommenting(comment.comment_id)"
                         v-if="!comment.comment_reply  && answering[comment.comment_id] && answering[comment.comment_id].answering">
                      <p>İptal</p>
                    </div>
                    <div class="comment-reply-save-button"
                         @click="sendComment(comment)"
                         v-if="!comment.comment_reply  && answering[comment.comment_id] && answering[comment.comment_id].answering">
                      <p>Gönder</p>
                    </div>
                  </div>
                </li>
                <div v-if="!commentsLoader" style="display: flex;justify-content: center">
                  <a v-if="seemoreComments" class="see-more-comments" @click="getGigComments(1)">Daha fazla göster</a>
                </div>
              </ul>
            </div>
            <div v-if="commentsLoader" style="margin: 10px">
              <loader style="width: 30px;height: 30px"></loader>
            </div>
          </div>
          <div style="padding-top: 56px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StarRating from 'vue-star-rating'
  import { fromNow } from '@/utils/dateFns'
  import isEmpty from 'lodash/isEmpty'
  import {bindCodeToHtml, generateCodeForGigDetail} from "@/utils/schema";

  export default {
    name: "src-pages-body-workstation-gigs-detail-v2-mobile",
    components: {
      StarRating
    },
    data() {
      return {
        pageLoaded: true,
        limit: 10,
        offset: 0,
        url: window.location.href,
        showDescription: false,
        videoLoader: false,
        screenWidth: (window.innerWidth),
        screenHeight: (window.innerHeight / 1.77),
        gigPortfolios: [],
        seller: [],
        title: null,
        gigCommentInfo: [],
        gigLikeInfo: [],

        gigDuration: null,
        gigDescription: null,
        seemoreDescription: true,
        seemoreComments: false,
        extras: [],
        priceInfo: [],
        uuid: null,
        quantity: null,
        commentsLoader: false,
        gigSlugURL: null,
        totalOfExtras: 0,
        gigComments: [],
        selectedExtras: [],
        swiperOption: {
          notNextTick: true,
          direction: 'horizontal',
          slidesPerView: 1,
          observeParents: true,
          pagination: '.swiper-pagination',
          paginationClickable: true,
          paginationBulletRender(swiper, index, className) {
            return `<span style="margin:0px 2px;width: 5px;height: 5px;background-color: #FFF;border: 1px solid #000" class="${className} swiper-pagination-bullet-custom"></span>`
            // return '<span class="' + className + ' swiper-pagination-bullet-custom' + '">' + (index + 1) + '</span>';
          }
        },
        answering: {},


        gig_id:null,
        packageCount: 1,
        packages: {
        	basic:{
        		extra_words:null
          }
        },
        words: null,
        packageSegment: 'basic'
      }
    },
    methods: {
      isEmpty,
      deactiveShowIframe() {
        this.gigPortfolios.forEach(portfolio => {
          portfolio.showIframe = false;
        });
        this.videoLoader = false;
      },
      activateIframe(receivedPortfolio) {
        this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'video play', gig_id:this.gig_id});

        this.gigPortfolios.forEach(portfolio => {
          portfolio.activatedIframe = portfolio.uuid === receivedPortfolio.uuid
        })

        if (receivedPortfolio.fileType === 'audio' || receivedPortfolio.fileType === 'video') {
          this.videoLoader = true;
        }
      },
      selectPieceOfSelectedExtra(receivedExtra, {target}) {
        if (!document.getElementById(`extra${receivedExtra.id}`).checked) {
          document.getElementById(`extra${receivedExtra.id}`).checked = true;
          this.selectedExtras.push(receivedExtra);
        }

        this.selectedExtras.forEach(function (extra) {
          if (receivedExtra.id === extra.id) {
            extra.quantity = Number(target.value);
          }
        });

        this.extras.forEach(function (extra) {
          if (receivedExtra.id === extra.id) {
            extra.quantity = Number(target.value);
          }
        });

        // vue, obje icindeki degisiklikleri gormuyor diye cloning yapiliyor
        this.selectedExtras = [...this.selectedExtras];
        this.extras = [...this.extras];
      },
      selectExtra(receivedExtra, event) {
        if (event.target.checked) {
          this.selectedExtras.push(receivedExtra);
        } else {
          if (this.selectedExtras.length) {
            this.selectedExtras = this.selectedExtras.filter(function (extra) {
              return receivedExtra.id !== extra.id;
            });
          }
        }
      },
      giveOrderMobile(pack) {
        if (!this.$store.state.user.uuid) {
          this.$router.push("/login");
        } else {

          if(this.packageCount > 1 && pack.extra_words && pack.extra_words.initial_words && pack.extra_words.initial_words > 0){
            this.words = pack.extra_words.initial_words;
          }



          this.api.orders.giveOrder(this.Constants.ORDER_TYPES.GIG, this.uuid, this.quantity, this.words, JSON.stringify(this.selectedExtras), pack.package_id, this.$Progress)
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.$router.push(result.data.redirect_url);
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }
            })
            .catch(err => {
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        }
      },
      getGigDetail() {
        this.api.general.getGigDetail(this.$store.state.routerParams[1])
          .then(({data}) => {
            this.pageLoaded = false;
            let result = data;
            if (result.success) {

              let extraWord = result.data.packages.basic.extra_words;


              this.$store.state.indexHTMLTitle = result.data.meta.title;
              this.$store.state.indexHTMLMetaDescription = result.data.meta.description;
              this.setFacebookMetaProperties(result.data.meta.facebookMeta);
              this.gigPortfolios = result.data.portfolios;
              this.seller = result.data.seller;
              this.title = result.data.title;
              this.gigCommentInfo = result.data.commentInfo;
              this.gigComments = result.data.commentInfo;
              if(result.data.commentInfo.comments.length){
                this.seemoreComments = true;
              }
              this.gigLikeInfo = result.data.likeInfo;
              this.gigDuration = result.data.duration;
              this.gigDescription = result.data.description;
              this.packages = result.data.packages;
              this.extraWord = extraWord;
              this.gig_id = result.data.gig_id;
              this.words = extraWord ? extraWord.initial_words : null
              this.packages.extras.forEach(e => {
                return e.quantity = 1;
              });
              this.packageCount = result.data.packageCount;
              result.data.gig_extras.forEach(extra => {
                extra.quantity = 1;
              });
              if (result.data.isFeatured) {
                this.getFeaturedGigAnalytics(result.data.featured_gigs_id);
              }
              this.extras = result.data.gig_extras;
              this.priceInfo = result.data.priceInfo;
              this.uuid = result.data.uuid;
              this.gigSlugURL = result.data.gig_slug_url;
              this.quantity = 1;
              this.Helper.trackEvents.pageView(this.Helper.gigDetailPagePropsV2(result.data));

              this.gigPortfolios.forEach(function (item) {
                item.activatedIframe = false;
              })
              const schema = {
                id: result.data.gig_id,
                price: result.data.packages.basic.price,
                slugUrl: result.data.gig_slug_url,
                imageUrl: result.data.content.image_url,
                title: result.data.title,
                username: result.data.seller.username,
                brand: result.data.gig_category_sub.name,
                ratingValue: result.data.commentInfo.commentRating,
                ratingCount: result.data.commentInfo.commentCount
              }
              if (result.data.packageCount > 1) {
                schema.maxPrice = result.data.packages.premium.price
              }
              bindCodeToHtml(JSON.stringify(generateCodeForGigDetail(schema, result.data.packageCount === 1)))

            } else {
              this.sendPrerender302(result.data.redirect_url,"302");
              if(!result.data.soft_redirect){
                this.$toasted.global.errorToast({description: "İlan veya kullanıcı artık aktif değil."});
              }
            }

          })
          .catch(err => {
            this.$toasted.error(err);
          })
      },
      getFeaturedGigAnalytics(featuredGigID) {
        this.api.feeds.featuredGigAnalytics(featuredGigID)
          .then(({data}) => {
            //
          })
          .catch(err => {
            this.$toasted.error(err);
          })
      },

      getGigComments(getMore) {


        if(getMore){
          this.Helper.trackEvents.customEvent("clicked", {version:'v1', page: this.$store.state.componentMap.page, what: 'gig comments', gig_id: this.gig_id});
        }

        if (getMore || this.gigCommentInfo.commentCount !== 0 && this.gigComments.length === 0) {

          this.$nextTick(() => {
            window.scrollTo(0, document.body.scrollHeight);
          });

          this.commentsLoader = true;
          this.seemoreComments = true;

          this.api.general.getGigComments(this.gigSlugURL, this.limit, this.offset, 'all')
            .then(({data}) => {
              let result = data;
              if (result.success) {
                this.commentsLoader = false;
                if (getMore) {
                  this.gigComments.comments = this.gigComments.comments.concat(result.data.comments);
                } else {
                  this.gigComments = result.data;
                }
                this.offset += this.limit;
                this.seemoreComments = result.data.seemorediv;
              }
            })
            .catch(err => {
              this.$toasted.error(err);
            })
        }
      },
      playMedia(item) {

        this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'video play', gig_id:this.gig_id});

        this.gigPortfolios.forEach(gigPortfolio => {
          if (gigPortfolio.uuid === item.uuid) {
            if (!gigPortfolio.showIframe && gigPortfolio.videoURL && (gigPortfolio.fileType === 'audio' || gigPortfolio.fileType === 'video')) {
              this.$nextTick(() => {
                gigPortfolio.showIframe = true;
              });
            }
          }
        });

        if (item.fileType === 'audio' || item.fileType === 'video') {
          this.videoLoader = true;
        }
      },

      deactivateIframeLoader() {
        this.videoLoader = false;
      },

      calculateDate(exDate) {
        return fromNow(exDate)
      },
      sendMessage(username) {
      	this.$store.state.clickedFrom = 'below avatar';
      	this.$store.state.clickedProps = {
		      gigID:this.gig_id
        };
      	this.$store.state.whereFrom = 'Gig'
        this.$router.push('/chat/' + username);
      },
      updateGigLikeStatus(uuid, action) {
        this.gigLikeInfo.likeStatus.actionIcon = "icon/icon_tansel_kalp_red.svg";
        this.api.general.updateLikeStatus(this.Constants.FAV_TYPES.GIG, action, uuid, null,this.$store.state.componentMap.page)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              //
            } else {
              this.$router.push(result.data.redirect_url);
              this.revertLikeStatus(this.gigLikeInfo);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.revertLikeStatus(this.gigLikeInfo);
          });
        this.reverseLikeStatus(this.gigLikeInfo);
      },
      reverseLikeStatus(receivedGig) {
        let newLikeIconText = "Favorilerime Ekle";
        let newLikedIconText = "Favorilerimden Kaldır";
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: newLikedIconText
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: newLikeIconText
          };
        }
      },
      revertLikeStatus(receivedGig) {
        let newLikeIconText = "Favorilerime Ekle";
        let newLikedIconText = "Favorilerimden Kaldır";
        if (receivedGig.likeStatus.action === this.imageUrls.likeIcon.action) {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likeIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likeIcon.action,
            actionText: newLikeIconText
          };
        } else {
          receivedGig.likeStatus = {
            actionIcon: this.imageUrls.likedIcon.url.split("https://gcdn.bionluk.com/site/").pop(),
            action: this.imageUrls.likedIcon.action,
            actionText: newLikedIconText
          };
        }
      },

      openUserProfilePage(username) {
        this.$router.push('/' + username);
      },

      openReplyTextArea(commentId) {
        let clone = Object.assign({}, this.answering);
        clone[commentId] = {};
        clone[commentId].answering = true;
        clone[commentId].commentReply = "";
        this.answering = clone;
      },

      sendComment(comment) {
        this.api.seller.replyComment(comment.comment_id, this.answering[comment.comment_id].commentReply)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              comment.comment_reply = this.answering[comment.comment_id].commentReply;
            } else {
              this.answering[comment.comment_id].commentReply = "";
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.answering[comment.comment_id].commentReply = "";
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      cancelCommenting(commentId) {
        let clone = Object.assign({}, this.answering);
        clone[commentId].answering = false;
        clone[commentId].commentReply = "";
        this.answering = clone;
      },

      totalPayment(segment) {


        let initialPrice;


        if(this.isEmpty(this.extraWord)){
	        if(this.priceInfo && this.priceInfo.buttonAction && this.priceInfo.buttonAction === 'message'){
		        initialPrice =  this.priceInfo.price;
          } else {
		        initialPrice = segment && this.packages[segment].price ? this.packages[segment].price : '';
          }
        } else {
          initialPrice = this.packages[this.packageSegment].price
        }
        let total = 0;

        if (this.isEmpty(this.extraWord)) {
          total = (total + initialPrice) * this.quantity;
        } else {
          const word = !parseInt(this.words) ? 0 : parseInt(this.words);
          const wordMultiplier = Math.ceil((word - this.extraWord.initial_words) / this.extraWord.words);
          total = (total + initialPrice);
          total = wordMultiplier > 0 ? total + (wordMultiplier * this.extraWord.price) : total;
        }

	      this.selectedExtras.forEach(function (extra) {
		      total += (extra.price * extra.quantity);
	      });
        return total.toLocaleString('tr');
      }
    },
    computed: {
    },
    watch:{
      '$route.params' : function (newval, oldval) {
        if(newval && oldval && newval !== oldval){
          this.pageLoaded = true;
          this.getGigDetail();
        }
      }
    },
    created() {
      this.getGigDetail();
    }
  }
</script>

<style scoped lang="scss">
  .header-title-container {
    padding-right: 18px;
  }

  .deed-detail-image {
    width: 100vw;
    object-fit: cover;
    background-color: #eceded;
  }

  .swiper-pagination {
    position: absolute;
  }

  .swiper-pagination-bullet-custom {
    width: 5px;
    height: 5px;

    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }

  .swiper-pagination-bullets {
    bottom: 26px;
  }

  .swiper-pagination-bullet-custom.swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
  }

  .swiper-pagination-bullet-active {
    background-color: red;
  }

  .deed-detail-user-container {
    padding: 0 15px;
  }

  .deed-detail-user-info {
    display: flex;
  }

  .deed-detail-user-info-container {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 14px;
    border-bottom: 2px solid #e6eaea;
  }

  .deed-detail-user-image-section {
    position: relative;
    display: inline-flex;
    align-self: center;
  }

  .deed-detail-user-image {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 100px;
    background-color: #eceded;
  }

  .deed-detail-user-online {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 6px;
    background-color: #ffb060;
    border-radius: 100px;
    border: 2px solid #FFF;
  }

  .deed-detail-user-name {
    font-size: 14px;
    color: #242424;
  }

  .deed-detail-show-more-less-text {
    font-size: 12px;
    color: #2d3640;
  }

  .deed-detail-show-more-less-arrow-image {
    width: 8px;
    height: 8px;
    margin-left: 10px;
  }

  .deed-detail-user-send-message-button {
    width: 100px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #2d3640;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    background-color: #FFF;
  }

  .deed-detail-user-name-online-container {
    display: flex;
    flex-direction: column;
    padding-left: 11px;
  }

  .deed-detail-user-social-container {
    margin-left: -12px;
    margin-right: -12px;
    background-color: #f4f5f7;
    padding: 18px 15px 20px;
    border-bottom: 2px solid #e6eaea;
    position: relative;
  }

  .deed-detail-user-social-list {
    display: flex;
    justify-content: center;
  }

  .deed-detail-user-social-list-item {
    font-size: 14px;
    color: #8b95a1;
    display: flex;
    justify-content: center;
    flex: 1;
    border-right: solid 2px #bfc8d2;

    &:last-child {
      border-right: none;
    }
  }

  .deed-detail-user-social-list-item-detail-last-seen, .deed-detail-user-social-list-item-detail-location {
    display: flex;
  }

  .deed-detail-user-social-list-item-detail-last-seen-image, .deed-detail-user-social-list-item-detail-location-image {
    padding-right: 6px;
  }

  .deed-detail-user-social-add-favorite {
    margin: 20px 0 15px 0;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #bfc8d2;
    display: flex;
    justify-content: center;
    padding: 13px 0;
  }

  .deed-detail-user-social-add-favorite-image {
    width: 16px;
    height: 15.4px;
    align-self: center;
  }

  .deed-detail-user-social-add-favorite-text {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #54585b;
    padding-left: 10px;
  }

  .deed-detail-user-social-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .deed-detail-user-social-links-header {
    font-size: 14px;
    color: #54585b;
  }

  .deed-detail-user-social-links-list {
    list-style-type: none;
    padding-top: 10px;
    display: flex;
  }

  .deed-detail-user-social-links-list-item {
    padding-right: 40px;

    &:last-child {
      padding-right: 0;
    }
  }

  .deed-detail-short-description-text {
    font-size: 18px;
    font-weight: 500;
    color: #2d3640;
    margin: 0;
  }

  .deed-detail-short-description-info-list-item-detail-days-number, .deed-detail-short-description-info-list-item-detail-comments-number, .deed-detail-short-description-info-list-item-detail-favs-number {
    font-weight: bold;
  }

  .deed-detail-short-description-info-list {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    align-items: center;
  }

  .deed-detail-short-description-info-list-item {
    padding: 5px 8px;
    font-size: 12px;
    color: #8b95a1;
    display: flex;
    height: 30px;
    flex: 1;
    justify-content: center;
    border-right: 2px solid #e6eaea;

    &:last-child {
      border-right: none;
    }
  }

  .deed-detail-short-description-container {
    padding: 20px 15px 12.5px;
    border-bottom: 5px solid #f4f5f7;
  }

  .deed-detail-short-description-info-list-item-detail {
    display: flex;
    align-items: center;
  }

  .deed-detail-short-description-info-list-item-detail-days-image, .deed-detail-short-description-info-list-item-detail-comments-image, .deed-detail-short-description-info-list-item-detail-favs-image {
    padding-right: 10px;
  }

  .deed-detail-short-description-info-list-item-detail-comments {
    text-decoration: underline;
  }

  .deed-detail-long-description-container {
    margin-top: 15px;
    border-bottom: 1px solid #e6eaea;
  }

  .deed-detail-long-description-header {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #2d3640;
  }

  .deed-detail-long-description-list-item-detail-text {
    font-size: 14px;
    line-height: 1.71;
    color: #8b95a1;
    overflow: hidden;
  }

  .deed-detail-long-description-list {
    list-style-type: none;
    position: relative;
    padding-top: 15px;
  }

  .deed-detail-long-description-list-item {
    position: relative;

    &:before {
      content: '\2012';
      position: absolute;
      margin-top: 2px;
    }
  }

  .deed-detail-long-description-list-item-detail-text {
    padding-left: 12px;
  }

  .deed-detail-long-description-list-see-more-link {
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: #2d3640;
    text-decoration: underline;
    padding-bottom: 15px;
    position: relative;
  }

  .deed-detail-long-description-list-see-more-background {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), #ffffff);
  }

  .deed-detail-extras {
    padding: 10px 0px;
    border-bottom: 1px solid #e6eaea;
  }

  .deed-detail-extras-header {
    font-size: 16px;
    font-weight: 600;
    color: #2d3640;
  }

  .deed-detail-extras-list-item-detail {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }

  .deed-detail-extras-list-item-detail-description-text {
    min-width: 160px;
    word-break: break-word;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
  }

  .deed-detail-extras-list-item-detail-description {
    padding-left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10px;
  }

  .deed-detail-extras-list-item-detail-control {
    align-self: center;

    input[type="checkbox"] {
      display: none;
    }

    input[type=checkbox] + label {
      display: inline-block;
      cursor: pointer;
      padding: 0 0 0 0px;
      background: url("https://gcdn.bionluk.com/site/icon/checkbox_empty.svg") no-repeat;
      height: 16px;
      width: 16px;;
    }

    input[type=checkbox]:checked + label {
      background: url("https://gcdn.bionluk.com/site/icon/checkbox_active.svg") no-repeat;
      height: 16px;
      width: 16px;
      display: inline-block;
      cursor: pointer;
    }
  }

  .deed-detail-extras-list-item-detail-description-day {
    font-size: 14px;
    font-weight: 500;
    color: #8b95a1;
    padding-top: 2px;
  }

  .deed-detail-extras-list-item-detail-count-selectbox, .deed-detail-order-quantity-select {
    width: 110px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #bfc8d2;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: url("https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg") right no-repeat;
    background-position-x: 90px;
    padding: 10px;
  }

  .deed-detail-extras-list-item-detail-count {
    padding-left: 10px;
  }

  .deed-detail-reviews {
    margin-top: 20px;
    padding: 15px 0;
  }

  .deed-detail-reviews-header {
    font-size: 16px;
    font-weight: 600;
    color: #2d3640;
    padding-bottom: 16px;
  }

  .deed-detail-reviews-info {
    background-color: #f4f5f7;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    height: 50px;
  }

  .deed-detail-reviews-info-rate {
    font-size: 32px;
    color: #ffb700;
  }

  .deed-detail-reviews-info-text {
    font-size: 14px;
    color: #939c9c;
    padding-left: 10px;
  }

  .deed-detail-reviews-info-review-count {
    color: #242424;
  }

  .deed-detail-reviews-description-list {
    display: flex;
    flex-direction: column;
    padding-top: 19px;
    padding-bottom: 5px;
  }

  .deed-detail-reviews-description-list-item-detail {
    display: flex;
    justify-content: space-between;
  }

  .deed-detail-reviews-description-list-item-detail-image {
    padding-right: 15px;
  }

  .deed-detail-reviews-description-list-item-detail-text {
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    color: #242424;
  }

  .deed-detail-reviews-description-list-item {
    padding-bottom: 25px;
  }

  .deed-detail-comments {
    padding-top: 2px;
    padding-bottom: 8px;
  }

  .deed-detail-comments-link {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #2d3640;
  }

  .deed-detail-user-social-links-list-item-detail-link-image {
    height: 16px;
  }

  .deed-detail-user-social-container-arrow {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px;

    -webkit-transform: rotate(-270deg);
    transform: rotate(-270deg);
  }

  .deed-detail-show-more-less-link {
    text-decoration: none;
  }

  .buy-now {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
  }

  .buy-now-currency {
    font-size: 24px;
    text-align: center;
    color: #2d3640;
    font-weight: normal;
    letter-spacing: -1.3px;
  }

  .deed-detail-comments-list-item-detail {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  .deed-detail-comments-list-item-user-container {
    display: flex;
    justify-content: space-between;
  }

  .deed-detail-comments-list-item-user {
    display: flex;
  }

  .deed-detail-comments-list-item-user-image {
    width: 30px;
    height: 30px;
    border-radius: 100px;
  }

  .deed-detail-comments-list-item-user-name, .deed-detail-comments-list-item-user-comment-date {
    font-size: 14px;
    color: #242424;
  }

  .deed-detail-comments-list-item-user-comment-date {
    color: #8b95a1;
  }

  .deed-detail-comments-list-item-detail-comment-text {
    font-size: 14px;
    color: #8b95a1;
  }

  .deed-detail-comments-list-item-detail-comment-container {
    padding-left: 40px;
  }

  .deed-detail-comments-list-item-detail-comment-text {
    word-break: break-word;
  }

  .comment-reply-button {
    cursor: pointer;
    margin-left: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 90px;
    height: 30px;
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.2);

    :hover {
      background-color: #ededed;
    }

    p {
      vertical-align: middle;
      line-height: 30px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
    }
  }

  .comment-reply-save-button {
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 90px;
    height: 30px;
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.2);

    :hover {
      background-color: #ededed;
    }

    p {
      vertical-align: middle;
      line-height: 30px;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #2d3640;
    }
  }

  .comment-reply-text-area {
    margin-left: 40px;
    margin-bottom: 15px;
    margin-top: 10px;
    width: calc(100% - 80px);
    display: flex;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      margin-right: 10px;
    }

    textarea {
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
      border: none;
      border-bottom: solid 1px #dfe4ec;
    }
  }

  .separator-review {
    width: 100%;
    height: 0;
    border-top: solid 1px #dfe4ec;
  }

  .showDescription-container-enter-active, .showDescription-container-leave-active {
    transition: all .4s;
  }

  .showDescription-container-enter, .showDescription-container-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  .deed-detail-comments-list-item {
    border-bottom: 1px solid #e6eaea;

    &:last-child {
      border-bottom: none;
    }
  }

  .deed-detail-extras-list-item-detail-count-selectbox, .deed-detail-order-quantity-select {
    font-size: 14px;
    font-weight: 600;
    color: #4b4f52;
  }

  .deed-detail-user-response-time {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .deed-detail-user-response-time-image {
    height: 18px;
    padding-right: 8px;
  }

  .deed-detail-user-response-time-text {
    font-size: 14px;
    color: #8b95a1;
  }

  .deed-detail-comments-list-item-reply-user-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0;
  }

  .deed-detail-comments-list-item-reply-user-name {
    color: #242424;
  }

  .deed-detail-comments-list-item-reply-comment {
    color: #8b95a1;
    padding-top: 2px;
    word-break: break-word;
  }

  .deed-detail-comments-list-item-reply-user-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .deed-detail-comments-list-item-reply-date-text {
    color: #8b95a1;
    padding-top: 2px;
  }

  .deed-detail-comments-list-item-reply {
    padding: 10px 0;
    margin-left: 40px;
    font-size: 14px;
    display: flex;
    border-top: 1px solid #e6eaea;
    align-items: flex-start;
  }

  .swiper-pagination {
    display: inline-flex;
    justify-content: center;
  }

  .deed-detail-user-social-list-item-detail-last-seen-text {
    font-size: 13px;
  }

  .see-more-comments {
    font-size: 14px;
    font-style: italic;
    cursor: pointer;
    text-decoration: underline;
    color: #2d3640;
    vertical-align: middle;
    line-height: 60px;
    text-align: center;
  }

  .playIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 48px !important;
    width: 48px !important;
    border: 2px solid #FFF;
    border-radius: 50%;
    background-color: #FFF;
  }

  .deed-detail-playMediaIcon {
    height: 48px !important;
    width: 48px !important;
  }

  /*packages-price */
  .packages-pricing-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 15px;
    margin: 10px 0;
  }

  .packages-container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .package-segment-container {
    display: flex;
  }

  .segment {
    width: 33.3333333333%;
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    color: #2d3640;
    border-bottom: 1px solid #ddd;
  }

  .active {
    color: #fd4056;
    border-bottom: 3px solid #fd4056;
  }

  .content-scope {
    display: flex;

    .passive {
      opacity: .6;
    }
  }

  .content-title {
    color: #2d3640;

    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }


  .content-img {
    width: 16px;
    height: 13px;
    margin-right: 9px;
    padding-top: 2px;

  }


  .package-content-title {
    font-weight: 500;
    color: #2d3640;
    font-size: 16px;
    margin-top: 20px;
  }

  .package-description {
    margin-top: 10px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 300;
    color: #4b4f52;
  }

  .package-include {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100%
  }

  .buy-now-button {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    width: 100%;
    height: 40px;
    border-radius: 2px;
    background-color: #26ca9a;

    &:focus {
      background-color: #00a575 !important;
      border-color: #00a575;
    }
  }

  .gig-quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }


</style>
